$primary: #1c1c2c;
$secondary: #8945b5;
$color: #fff;
$secondaryColor: #bb82e2;
$altColor: #000;

$mobile-width: 950px;

// Navbar
$navbarBackground: #26263c;
$hoverTextColor: #95a5a6;

//HOME
$homeFirstElement: #76b18e;
$homeFirstElementSecond: #ad3b45;
$homeSecondElement: #c9b757;
$homeThirdElement: #242f4d;

//PAGES
$pagesGreen: #244d47;
$pagesOrange: #523e29;
$pagesRed: #522d29;
