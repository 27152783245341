@use "../../styles/variables.scss" as v;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 15px;

  div {
    display: grid;
    grid-template-rows: 1fr 1fr;
  }

  #red {
    color: v.$homeFirstElementSecond;
  }

  #close {
    img {
      cursor: pointer;

      transition: transform 0.4s ease-in-out;

      &:hover {
        transform: rotate(180deg);
        transition: transform 0.4s ease-in-out;
      }
    }
  }
}

.modal-content {
  input {
    cursor: pointer;
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid v.$secondary;
    border-radius: 2px;
    padding: 15px;
    margin: 10px;
    color: #fff;
  }

  button {
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;

    width: 100%;
    padding: 10px;
    margin: 10px;

    border-radius: 5px;
    color: white;

    border: 2px solid v.$secondary;

    transition: background 0.3s ease-in-out;

    &:hover {
      background: rgba($color: v.$secondary, $alpha: 0.6);
      color: #fff;
    }
  }

  textarea {
    cursor: pointer;
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid v.$secondary;
    border-radius: 2px;
    padding: 15px;
    margin: 10px;
    color: #fff;
    resize: none;
  }

  #g-bnt {
    border-color: v.$homeFirstElement !important;

    &:hover {
      background: rgba($color: v.$homeFirstElement, $alpha: 0.6) !important;
      color: #fff;
    }
  }

  #r-bnt {
    border-color: v.$homeFirstElementSecond !important;

    &:hover {
      background: rgba(
        $color: v.$homeFirstElementSecond,
        $alpha: 0.6
      ) !important;
      color: #fff;
    }
  }
}
