@use "./variables.scss" as v;

.colorBlendContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  label {
    font-size: 1.1rem;
  }

  #inputText {
    background: none !important;
    padding: 0 !important;
    width: 100px !important;
    font-size: 1rem;
    color: v.$secondaryColor !important;
  }

  #color {
    margin: 20px;
  }

  #midpoints {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
      width: 30px;

      margin: 0 10px;
      cursor: pointer;

      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: rotate(180deg);
      }
    }
  }
}

.colorBlendHexPicker {
  // background-color: v.$navbarBackground;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.colorsOutput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .colorBox {
    width: 150px;
    height: 150px;
    border-radius: 5px;
    margin: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
