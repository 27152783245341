@use "./variables.scss" as v;
@import url("./hamburgers.scss");

.navbar {
  position: fixed;
  background: v.$navbarBackground;
  width: 100%;
  z-index: 100;

  @media screen and (min-width: v.$mobile-width) {
    .hamburger {
      display: none;
    }

    .nav {
      display: grid;

      grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
      grid-column-start: revert;
      gap: 20px;
      font-size: 1.1rem;

      a {
        margin-top: 10px;
        display: flex;

        justify-content: center;
        justify-items: center;
        align-items: center;

        width: 150px;
        color: white;
        text-decoration: none;
        div {
          position: relative;
          display: inline-flex;
          align-items: center;

          padding: 15px;
          width: 100%;

          img {
            width: 32px;
          }

          span {
            margin-left: 25px;
            line-height: 22px;
          }

          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            top: 100%;
            left: 0;

            background: v.$secondary;
            transition: transform 0.5s;
            border-radius: 5px;
            transform: scaleX(0);
            transform-origin: right;
          }

          &:hover {
            color: v.$hoverTextColor;
            cursor: pointer;
          }

          &:hover::after {
            transform: scaleX(1);
            transform-origin: left;
          }
        }

        .dropdown {
          border: 2px solid red;
          height: 200px;
        }
      }

      .dropdown {
        float: left;
        overflow: hidden;
      }

      .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
      }

      .dropdown-content a {
        float: none;
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
      }
    }
  }

  @media screen and (max-width: v.$mobile-width) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .logo {
      width: auto;
      margin: auto;

      img {
        max-width: 200px;
      }
    }

    .nav {
      display: none;
      font-size: 1.1rem;
      a {
        color: white;
        text-decoration: none;

        div {
          width: max-content;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          img {
            width: 20px;
          }

          span {
            margin-left: 25px;
          }

          margin: 15px;
          padding: 15px;
        }
      }
    }
  }
}
