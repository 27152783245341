@use "./variables.scss" as v;

.privacy {
  padding: 100px 15px 15px 15px;
  text-align: left;

  p {
    padding: 0 0 0 15px;
    margin: 5px;
  }

  ul {
    padding: 0 0 0 40px;
    margin: 5px;
  }
}

#footer {
  position: fixed;
  bottom: 0px;
  width: 100vw;
}
