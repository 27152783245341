.gridGenPanel {
  border: 2px solid #598188;

  display: grid;

  width: 100%;
  height: 50%;

  div {
    border: 1px dashed #6c5988;
    width: 100%;
    height: 100%;
  }
}
