@use "./variables.scss" as v;

.accountPanel {
  display: grid;
  grid-template-rows: 100px auto;
  .accountPanelTile {
    width: fit-content;
    margin: auto;
    display: flex;
    flex-direction: column;

    #accountBnt {
      display: flex;
      justify-content: center;
      justify-items: center;
      button {
        cursor: pointer;
        border: none;
        outline: none;
        background-color: transparent;

        border: 2px solid v.$secondary;
        border-radius: 8px;
        margin: 15px;

        color: v.$color;
        padding: 15px;

        transition: background-color 0.4s ease-in-out;

        &:hover {
          background-color: rgba($color: v.$secondary, $alpha: 0.2);
        }
      }

      #logout {
        border-color: v.$homeFirstElementSecond;
        &:hover {
          background-color: rgba(
            $color: v.$homeFirstElementSecond,
            $alpha: 0.2
          );
        }
      }
    }

    #title {
      text-align: center;
    }
  }

  #personalDetail {
    margin: 15px;
  }

  #footer {
    position: fixed;
    bottom: 0px;
    width: 100vw;
  }
}
