@use "./variables.scss" as v;

.homePanel {
  overflow-x: hidden;
  height: 100vh;

  display: grid;
  grid-template-rows: 300px auto minmax(500px, auto);

  @media screen and (max-width: v.$mobile-width) {
    grid-template-rows: 300px auto repeat(2, minmax(550px, auto)) 100px;
  }

  .wave {
    max-width: 100vw;
    img {
      height: 100%;
    }
  }

  .homeTitle {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;

    max-width: 100vw;

    div {
      width: fit-content;

      margin-top: 50px;
      span {
        font-size: 2rem;
        font-weight: bold;

        sub {
          color: v.$secondary;
          font-size: 14px;
          font-weight: normal;
          padding-left: 8px;
        }
      }
    }

    @media screen and (max-width: v.$mobile-width) {
      max-width: 100vw;
      div {
        span {
          font-size: 1.4rem;
        }
      }
    }
  }

  .homeContent {
    display: grid;

    @media screen and (max-width: v.$mobile-width) {
      grid-template-rows: 100px auto;
    }

    max-width: 100vw;

    &:first-child {
      grid-template-rows: auto 500px;
    }

    .homeContentElement {
      max-width: 100vw;
      display: flex;
      justify-content: center;
      padding-bottom: 2rem;

      #title {
        text-align: center;
        height: fit-content;
        span {
          font-size: 1.4rem;
          color: v.$altColor;
        }
      }

      #lmenu {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        cursor: pointer;

        a {
          text-decoration: none;
          color: v.$color;
          display: grid;
          grid-template-columns: 24px auto;

          justify-items: center;
          align-items: center;
          border: none;
          outline: none;
          background: none;
          width: 180px;
          height: 50px;
          margin: 15px;

          border: 2px solid v.$primary;
          border-radius: 20px;
          background-color: rgba($color: v.$primary, $alpha: 0.8);
          transition: background-color 0.5s ease-in-out;

          img {
            margin-left: 15px;
            width: 24px;
          }

          &:hover {
            background-color: rgba($color: v.$primary, $alpha: 0.5);
          }
        }
      }

      #centerDiv {
        position: relative;
        div {
          position: absolute;
          transition: top 0.5s ease-in-out, left 0.5s ease-in-out;
          transform: translate(-50%, -50%);
          border: 2px solid v.$secondary;
          border-radius: 20px;
          background-color: rgba($color: v.$secondary, $alpha: 0.4);
          width: 100px;
          height: 100px;
        }
      }

      #codeTag {
        margin: 15px;
        max-width: 90vw;
      }
    }

    .hc-f {
      display: grid;
      margin-top: -20px;

      width: 100%;
      height: 600px;
    }

    .green {
      background-color: v.$homeFirstElement;
    }

    .yellow {
      background-color: v.$homeSecondElement;
      grid-template-rows: 100px 1fr;
    }

    .red {
      background-color: v.$pagesRed;
      color: v.$color;
    }
  }

  .arrow {
    width: fit-content;
    transform: rotate(90deg);
    position: fixed;
    bottom: 0%;
    left: 50%;

    animation-name: arrowAnim;
    animation-iteration-count: infinite;
    animation-duration: 1.5s;
  }
}

.solo {
  grid-template-rows: 300px auto fit-content(80%) 100px;

  @media screen and (max-width: v.$mobile-width) {
    grid-template-rows: 300px auto fit-content(80%) 100px;
  }
}

@keyframes arrowAnim {
  0% {
    bottom: 0%;
  }
  50% {
    bottom: 2.5%;
  }
  100% {
    bottom: 0%;
  }
}
