@use "./variables.scss" as v;

@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap");
@import url("./slider.scss");
@import url("./navbar.scss");
@import url("./home.scss");
@import url("./pages.scss");
@import url("./loginPanel.scss");
@import url("./account.scss");
@import url("./code.scss");
@import url("./footer.scss");
@import url("./privacy.scss");
@import url("./colorBlender.scss");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Fira Code", monospace;
}

body {
  background-color: v.$primary;
  color: white;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: v.$secondary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: v.$secondary;
}

.c-secondary {
  color: v.$secondary;
}
