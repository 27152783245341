@use "./variables.scss" as v;

.pagePanel {
  display: grid;
  grid-template-rows: auto;
  max-width: 100vw;
  max-height: 100vh;
  @media screen and (max-width: v.$mobile-width) {
    max-height: 150vh;
  }

  .contentContainer {
    padding: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: v.$mobile-width) {
      display: flex;
      flex-direction: column;

      .pagePanelTitle {
        margin: 25px;
      }
    }

    max-height: 100vh;

    @media screen and (max-width: v.$mobile-width) {
      max-height: 150vh;
    }

    max-width: 100vw;

    .pagePanelTitle {
      display: flex;
      flex-direction: column;
      justify-content: center;

      #title {
        text-align: center;
        font-size: 1.1rem;
        margin: 15px;
      }

      #content {
        width: fit-content;
        margin: auto;

        width: 100%;
        height: 100%;

        label {
          font-size: 0.8rem;
          padding: 15px;
        }

        input[type="text"],
        input[type="number"] {
          border: none;
          outline: none;
          background-color: transparent;
          width: 100%;
          border-bottom: 2px solid v.$secondary;
          color: v.$color;

          margin-bottom: 15px;
          padding: 15px;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          // display: none; /* <- Crashes Chrome on hover */
          -webkit-appearance: none;
          margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        input[type="number"] {
          -moz-appearance: textfield; /* Firefox */
        }

        button {
          cursor: pointer;
          border: none;
          outline: none;
          background-color: transparent;

          border: 2px solid v.$secondary;
          border-radius: 8px;
          width: 100%;

          color: v.$color;
          padding: 15px;

          transition: background-color 0.4s ease-in-out;

          &:hover {
            background-color: rgba($color: v.$secondary, $alpha: 0.2);
          }
        }

        select {
          border: none;
          outline: none;
          background-color: transparent;
          width: 100%;
          border-bottom: 2px solid v.$secondary;
          color: v.$color;

          margin-bottom: 15px;
          padding: 15px;

          option {
            border: none;
            outline: none;
            background-color: #3a3b38;
          }
        }
      }

      #bnt {
        display: flex;
        button {
          margin: 5px;
        }
      }
    }

    #output {
      margin-left: 5px;
      max-height: 600px;

      padding: 15px;
      overflow-y: scroll;
      #center {
        display: flex;
        justify-content: center;
      }
    }

    .flexBoxGenBoard {
      padding: 15px;

      overflow: hidden;

      div {
        border-radius: 15px;
        width: 100px;

        margin: 15px;

        display: flex;
        justify-content: center;
        align-items: center;
        text-shadow: 1px 1px 0px #121212;
      }
    }
  }

  .blue {
    background-color: v.$homeThirdElement;
  }

  .green {
    background-color: v.$pagesGreen;
  }

  .orange {
    background-color: v.$pagesOrange;
  }

  .red {
    background-color: v.$pagesRed;
  }
}
