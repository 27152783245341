@use "../../styles/variables.scss" as v;

.jv-con {
  display: flex;
  flex-direction: column;
  margin: 15px;

  span {
    margin: 5px;
  }
}

.jv-symbol {
  color: #fff;
  font-weight: bold;
}

.jv-current {
  line-height: 30px;
  padding-left: 20px;
  position: relative;
}

.jv-String {
  display: inline-block;
  color: #66d9ef;
  line-break: anywhere;
}

.jv-Boolean {
  display: inline-block;
  color: #a6e22e;
}

.jv-Number {
  display: inline-block;
  color: #f92672;
}

.jv-Null {
  display: inline-block;
  color: #e6db74;
}

.jv-folder {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  span {
    margin: 5px 0 5px 0;
  }
}

.jvPanel {
  div {
    input[type="text"],
    input[type="number"],
    textarea {
      resize: none;
      border: none;
      outline: none;
      background-color: transparent;
      width: 100%;
      border-bottom: 2px solid v.$secondary;
      color: v.$color;

      margin-bottom: 15px;
      padding: 15px;
    }

    button {
      cursor: pointer;
      border: none;
      outline: none;
      background-color: transparent;

      border: 2px solid v.$secondary;
      border-radius: 8px;
      width: 100%;

      color: v.$color;
      padding: 15px;

      transition: background-color 0.4s ease-in-out;

      &:hover {
        background-color: rgba($color: v.$secondary, $alpha: 0.2);
      }
    }
  }
}
