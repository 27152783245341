@use "./variables.scss" as v;

.slider {
  margin: 15px;

  -webkit-appearance: none;
  width: 100%;

  @media screen and (max-width: v.$mobile-width) {
    width: 90%;
  }

  background: rgba($color: v.$secondary, $alpha: 0.5);
  border-radius: 5px;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: v.$color;
    cursor: pointer;
    border-radius: 50%;
  }

  &::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #04aa6d;
    cursor: pointer;
  }
}
